<template>
  <v-container class="mt-4 px-5">
    <v-card outlined class="rounded-lg" @click.prevent="support">
      <v-img
        aspect-ratio="2.4"
        eager
        lazy-src="@/assets/img/Support.svg"
        src="@/assets/img/Support.svg"
      ></v-img>
      <v-card-title class="justify-center text-caption black--text">
          <center><strong>Ada Pertanyaan?</strong> Tim Kami Siap Membantu</center>
      </v-card-title>
    </v-card>
  </v-container>
</template>

<script>
export default {
  methods: {
    support() {
      window.open(
        "https://www.instagram.com/pemol.id/",
        "_blank"
      );
    }
  }
}
</script>