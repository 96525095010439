import Vue from "vue";
import VueRouter from "vue-router";
import VueMeta from 'vue-meta'
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const moment = require('moment')
require('moment/locale/id')
 
Vue.use(require('vue-moment'), {
    moment
})
Vue.use(VueMeta)

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/order",
    name: "Order",
    component: () => import(/* webpackChunkName: "order" */ "../views/Order.vue"),
  },
  {
    path: "/katalog",
    name: "katalog_sampah",
    component: () => import(/* webpackChunkName: "katalog" */ "../views/Katalog.vue"),
  },
  {
    path: "/detail",
    name: "detail_order",
    component: () => import(/* webpackChunkName: "detail" */ "../views/DetailOrder.vue"),
  },
  {
    path: "/orderSukses/:phoneNumber",
    name: "order_sukses",
    props: true,
    component: () => import(/* webpackChunkName: "detail" */ "../views/OrderSukses.vue"),
  },
  {
    path: "/riwayat/:id",
    name: "Riwayat",
    props: true,
    component: () => import(/* webpackChunkName: "detail" */ "../views/Riwayat.vue"),
  },
  { path: '*', redirect: '/' }, // catch all use case
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
