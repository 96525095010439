import Vue from 'vue'
import Vuex from 'vuex'
import dataOrder from './dataOrder'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    //
  },

  mutations: {
    //
  },

  actions: {
    //
  },

  modules: {
    dataOrder
  },
})
