<template>
  <v-container class="py-0 px-5">
    <v-card tile elevation="0">
      <v-card-title class="pt-0 mt-5 justify-center text-caption resi-text">
        <center>
          Kamu bisa cek transaksi yang sedang berlangsung ataupun sudah selesai
          di sini
        </center>
      </v-card-title>
    </v-card>

    <v-card elevation="0" class="rounded-pill">
      <v-card-text class="pa-0">
        <v-btn
          class="font-weight-bold text-subtite-2 resi-text"
          :disabled="disabled"
          large
          block
          rounded
          outlined
          style="text-transform: unset !important"
          @click.prevent="lihatResi({ id: orderID })"
        >
          <v-icon left>{{ iconResi }}</v-icon>
          {{ textResi }}
        </v-btn>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="alert"
      :transition="transition"
      persistent
      class="rounded-lg"
      max-width="450"
    >
      <v-card tile elevation="0" class="rounded-lg pb-3">
        <v-toolbar dense tile elevation="0">
          <v-btn small icon @click.prevent="mengerti">
            <v-icon small color="black">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-title class="py-0 text-h6 font-weight-bold black--text">
          Status Orderan
        </v-card-title>
        <v-card-subtitle class="py-4 text-subtitle-2 black--text">
          {{ title }}
        </v-card-subtitle>

        <v-card-actions>
          <v-btn
            depressed
            block
            dark
            color="#EB4646"
            style="text-transform: unset !important"
            @click.prevent="mengerti"
          >
            Mengerti
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  name: "makeOrder",
  data: () => ({
    alert: false,
    disabled: false,
    title: "",
    transition: "dialog-bottom-transition",
    orderID: "0",
    iconResi: "",
    textResi: "",
  }),
  methods: {
    mengerti() {
      this.$router.go(this.$router.currentRoute);
      window.scrollTo({
        top: 0,
        left: 0,
      });
    },
    lihatResi(id) {
      var localOrderId = localStorage.getItem("orderId");
      if (localOrderId !== null) {
        axios.get("ios/transaction/" + id.id).then((response) => {
          if (response.data.data.status === "1") {
            this.$router.push({ name: "Riwayat", params: id });
            window.scrollTo({
              top: 0,
              left: 0,
            });
          } else {
            localStorage.removeItem("orderId");
            this.alert = true;
            this.title =
              "Orderan kamu telah selesai. Lakukan orderan berikutnya untuk melihat resi. Terima Kasih telah menggunakan Pemol :)";
          }
        });
      } else {
        this.alert = true;
        this.title =
          "Kamu tidak mempunyai orderan aktif. Lakukan penjualan sampah untuk melihat status penjemputan sampah kamu.";
      }
    },
  },
  created() {
    var id = localStorage.getItem("orderId");
    if (id === null) {
      this.disabled = true;
      this.iconResi = "mdi-numeric-0-circle";
      this.textResi = "Belum ada riwayat transaksi";
    } else {
      this.disabled = false;
      this.iconResi = "mdi-numeric-1-circle";
      this.textResi = "Ada transaksi aktif";
      this.orderID = localStorage.getItem("orderId");
    }
  },
};
</script>

<style scoped>
.text-top-title {
  font-size: 17.5px !important;
}
.resi-text {
  color: #7a7a7a;
}
</style>