<template>
  <v-app>
    <v-main style="background: #E0E0E0">
      <v-container class="pa-0 mainContent">
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style scoped>
.mainContent {
  width: 480px;
  max-width: 100%;
  margin: auto;
  background: white;
}
</style>