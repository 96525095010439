<template>
  <v-container class="px-5">
    <v-sheet color="#F4F4F4" height="1" class="mt-5"> </v-sheet>
    <v-row>
      <v-col>
        <v-card tile elevation="0">
          <v-card-title class="justify-center text-caption black--text">
            Copyright © {{ new Date().getFullYear() }} Pemol. All Rights Reserved
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Footer",
}
</script>