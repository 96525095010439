<template>
  <v-container class="pa-0">
    <v-sheet class="mb-5" color="#EB4646" style="border: 1px solid #eb4646">
      <v-list-item class="pa-0 mx-5 my-1">
        <v-list-item-content class="pa-0">
          <logo-home />
        </v-list-item-content>
        <v-list-item-avatar tile width="auto">
          <v-btn
            class="text-caption font-weight-normal white--text"
            depressed
            rounded
            color="#BC3838"
            small
            style="text-transform: unset !important"
            @click.prevent="dialogInfoPemol"
          >
            Apa itu Pemol versi web ?
          </v-btn>
        </v-list-item-avatar>
      </v-list-item>
    </v-sheet>
    <banner />
    <devider />
    <make-order />
    <devider />
    <resi />
    <devider />
    <support />
    <devider />
    <download-apps />
    <Footer />

    <v-dialog
      v-model="infoPemol"
      :transition="transition"
      persistent
      class="rounded-lg"
      max-width="450"
    >
      <v-card class="rounded-lg pb-3">
        <v-card-title class="text-subtitle-1 font-weight-bold black--text">
          Apa itu Pemol versi WebApp
        </v-card-title>
        <v-card-subtitle class="py-2 text-subtitle-2 black--text">
          Ini adalah versi website yang dapat diakses oleh semua pengguna namun
          dengan fitur terbatas. Perlu diketahui, terdapat
          <strong><i>biaya layanan sebesar 15%</i></strong> untuk orderan
          melalui laman ini
        </v-card-subtitle>
        <v-card-actions>
          <v-btn
            depressed
            block
            dark
            color="#EB4646"
            style="text-transform: unset !important"
            @click="infoPemol = !infoPemol"
          >
            Mengerti
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="alert"
      :transition="transition"
      persistent
      class="rounded-lg"
      max-width="450"
    >
      <v-card class="rounded-lg pb-3">
        <v-list-item class="pt-4 pb-2">
          <v-list-item-avatar tile>
            <v-icon color="blue">mdi-map-marker-radius</v-icon>            
          </v-list-item-avatar>
          <v-list-item-content class="pa-0 text-subtitle-2 black--text">
            Aplikasi ini membutuhkan akses lokasi anda. Aktifkan
            GPS untuk mulai menggunakan aplikasi ini.
          </v-list-item-content>
        </v-list-item>
        <v-card-actions>
          <v-btn
            depressed
            block
            dark
            color="#EB4646"
            style="text-transform: unset !important"
            @click="refreshPage"
          >
            Mengerti
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import LogoHome from "@/components/Home/LogoHome.vue";
import Banner from "@/components/Home/Banner.vue";
import MakeOrder from "@/components/Home/MakeOrder.vue";
import Resi from "@/components/Home/Resi.vue";
import Support from "@/components/Home/Support.vue";
import DownloadApps from "@/components/Home/DownloadApps.vue";
import Footer from "@/components/Home/Footer.vue";
import Devider from "@/components/Home/Devider.vue";

export default {
  name: "Home",

  metaInfo: {
    title: "Pemol",
    titleTemplate: "%s - Pilah Sampahmu!",
    htmlAttrs: {
      lang: "id",
      amp: true,
    },
  },

  components: {
    LogoHome,
    Banner,
    MakeOrder,
    Resi,
    Support,
    DownloadApps,
    Footer,
    Devider,
  },

  data: () => ({
    infoPemol: false,
    alert: false,
    transition: "dialog-top-transition",
    position: null,
  }),

  methods: {
    dialogInfoPemol() {
      this.infoPemol = true;
    },
    activateNavigator() {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.position = `${position.coords.latitude},${position.coords.longitude}`;
          if (this.position === null) {
            this.alert = true;
          }
        },
        (error) => {
          if (this.position === null) {
            this.alert = true;
          }
          console.log(error.message);
        }
      );
    },
    refreshPage() {
      this.$router.go(this.$router.currentRoute);
      window.scrollTo({
        top: 0,
        left: 0,
      });
    },
  },

  created() {
    this.activateNavigator();
    localStorage.removeItem("dataSampah");
    localStorage.removeItem("dataTransaksi");
    localStorage.removeItem("img");
  },
};
</script>
