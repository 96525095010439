<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card tile elevation="0">
          <v-card-title
            class="justify-center text-top-title font-weight-bold black--text"
          >
            Versi Aplikasi Pemol Tersedia!
          </v-card-title>
          <v-card-subtitle class="pb-1 text-caption resi-text">
            <center>
              Nikmati fitur tabung, penarikan tunai, belanja, PemolPay, bayar
              tagihan dan produk digital lainnya.
            </center>
          </v-card-subtitle>
          <v-card-text class="py-1">
            <center>
              <v-img
                contain
                lazy-src="@/assets/img/playstore.svg"
                src="@/assets/img/playstore.svg"
                eager
                width="145"
                @click.prevent="openPlayStore"
              ></v-img>
            </center>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "downloadApps",
  data: () => ({
    linkPlaystore:
      "https://play.google.com/store/apps/details?id=com.tdb.pemol.pemol",
  }),
  methods: {
    openPlayStore() {
      window.location.href = this.linkPlaystore;
    },
  },
};
</script>

<style scoped>
.text-top-title {
  font-size: 17.5px !important;
}
.resi-text {
  color: #7a7a7a;
}
</style>