<template>
  <v-container class="py-0 px-5">
    <v-card tile elevation="0">
      <v-card-title
        class="pa-0 mt-5 justify-center text-top-title font-weight-bold black--text"
      >
        Mulai Ubah Sampah Jadi Uang!
      </v-card-title>
    </v-card>

    <v-card color="#EB4646" class="rounded-lg" @click.prevent="jualSampah">
      <v-list-item class="py-0 mt-5">
        <v-list-item-avatar tile>
          <v-img
            max-width="50"
            contain
            eager
            lazy-src="@/assets/img/Ikon_50_50.svg"
            src="@/assets/img/Ikon_50_50.svg"
          ></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title
            class="mb-2 text-subtitile-2 font-weight-bold white--text"
          >
            Jual Sampah
          </v-list-item-title>
          <v-list-item-subtitle class="text-caption white--text">
            Dijemput dan dibayarkan
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-avatar tile size="24">
          <v-icon dark> mdi-chevron-right </v-icon>
        </v-list-item-avatar>
      </v-list-item>
    </v-card>

    <v-dialog
      v-model="alert"
      :transition="transition"
      persistent
      class="rounded-lg"
      max-width="450"
    >
      <v-card tile elevation="0" class="rounded-lg pb-3">
        <v-toolbar dense tile elevation="0">
          <v-btn small icon @click.prevent="mengerti">
            <v-icon small color="black">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-title class="py-0 text-h6 font-weight-bold black--text">
          Status Orderan
        </v-card-title>
        <v-card-subtitle class="py-4 text-subtitle-2 black--text">
          {{ title }}
        </v-card-subtitle>

        <v-card-actions>
          <v-btn
            depressed
            block
            dark
            color="#EB4646"
            style="text-transform: unset !important"
            @click.prevent="mengerti"
          >
            Mengerti
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: "makeOrder",
  data: () => ({
    alert: false,
    disabled: false,
    title: "",
    transition: "dialog-bottom-transition",
    orderID: "0",
    iconResi: "",
    textResi: "",
  }),
  methods: {
    mengerti() {
      this.$router.go(this.$router.currentRoute);
      window.scrollTo({
        top: 0,
        left: 0,
      });
    },
    jualSampah() {
      var localOrderId = localStorage.getItem("orderId");
      if (localOrderId !== null) {
        this.alert = true;
        this.title =
          "Kamu mempunyai orderan aktif yang sedang dalam perjalanan untuk dijemput. Penjualan sampah dapat dilakukan jika orderan sebelumnya telah selesai";
      } else {
        this.$router.push({ name: "katalog_sampah" });
        window.scrollTo({
          top: 0,
          left: 0,
        });
      }
    },
  },
};
</script>

<style scoped>
.text-top-title {
  font-size: 17.5px !important;
}
.resi-text {
  color: #7a7a7a;
}
</style>