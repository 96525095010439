<template>
  <v-container>
    <v-row>
      <v-col class="pa-0">
        <v-carousel
          continuous
          cycle
          :show-arrows="false"
          hide-delimiter-background
          delimiter-icon="mdi-minus"
          height="auto"
          class="px-5"
        >
          <template v-if="dataBanner.length != 0">
            <v-carousel-item v-for="banner in dataBanner" :key="banner.id">
              <v-card class="rounded-lg">
                <v-img
                  eager
                  :lazy-src="'https://api.pemol.id/banner/' + banner.img"
                  :src="'https://api.pemol.id/banner/' + banner.img"
                  aspect-ratio="2.7"
                  @click.prevent="openImg(banner.url)"
                ></v-img>
              </v-card>
            </v-carousel-item>
          </template>
          <template v-else>
            <v-carousel-item>
              <v-skeleton-loader
                type="image"
                class="rounded-lg"
                height="145"
              ></v-skeleton-loader>
            </v-carousel-item>
          </template>
        </v-carousel>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "Banner",
  data: () => ({
    dataBanner: [],
  }),
  methods: {
    async getBanner() {
      let tempBanner = [];
      await axios.get("ios/section").then((response) => {
        tempBanner = response.data.data;
        tempBanner.forEach((value) => {
          if (value.categories === "Promo" && value.status === "aktif") {
            this.dataBanner.push({
              id: value.id_promo,
              img: value.featured_image,
              url: value.url,
            });
          }
        });
      });
    },
    openImg(value) {
      window.open(value, "_blank");
    },
  },
  mounted() {
    this.getBanner();
  },
};
</script>
