export default {
    namespaced: true,

    state: {
        dataSampah: [],
        dataTransaksi: [],
    },

    getters: {
        dataSampah(state){
            return state.dataSampah
        },
        dataTransaksi(state){
            return state.dataTransaksi
        },
    },

    mutations: {
        SET_DATA_SAMPAH(state, payload) {
            state.dataSampah = payload
            localStorage.setItem('dataSampah', JSON.stringify(payload))
        },
        SET_DATA_TRANSAKSI(state, payload) {
            state.dataTransaksi = payload
            localStorage.setItem('dataTransaksi', JSON.stringify(payload))
        }
    },

    actions: {
        setDataSampah({ commit }, payload){
            localStorage.removeItem('dataSampah')
            commit('SET_DATA_SAMPAH', payload)
        },
        setDataTransaksi({ commit }, payload){
            localStorage.removeItem('dataTransaksi')
            commit('SET_DATA_TRANSAKSI', payload)
        }
    }
}